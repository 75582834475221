import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { Redirector } from '../app/components/survey-redirector/redirector';

@NgModule({
  imports: [
    RouterModule.forRoot([
      {

      path: 'publicsurvey', component: Redirector },
    ], { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', onSameUrlNavigation: 'reload' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
