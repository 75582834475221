import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class SurveyRedirectService {

  constructor(private http: HttpClient) { }

  getSurveys() {
    return this.http.get<any>('/assets/surveydata/surveys.json')
      .toPromise()
      .then(res => res.data as any[])
      .then(data => data);
  }

  getIfNotFound() {
    return this.http.get<any>('/assets/surveydata/surveys.json')
      .toPromise()
      .then(res => res.IfNotFoundBaseUrl)
      .then(data => data);
  }
}
