import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppComponent } from './app.component';
import {Redirector} from "./components/survey-redirector/redirector";
import {AppRoutingModule} from "./app-routing.module";
import {SurveyRedirectService} from "./service/survey.redirect.service";
import { HTTP_INTERCEPTORS, HttpClientModule,HttpClient } from "@angular/common/http";
@NgModule({
  declarations: [
    AppComponent,Redirector
  ],
  imports: [HttpClientModule,
    AppRoutingModule,
    BrowserModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    SurveyRedirectService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
