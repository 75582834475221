import {Component, OnDestroy, OnInit} from '@angular/core';
import {SurveyRedirectService} from "../../service/survey.redirect.service";
import { Location } from '@angular/common';
import { Router } from '@angular/router';
@Component({
  templateUrl: './redirector.html',
})


export class Redirector implements OnInit, OnDestroy {


  surveys: any[] = [];
  redirectUrlWithId:any;

  constructor(private surveyRedirectorService: SurveyRedirectService,private router : Router) {
  }
  ngOnDestroy(): void {
  }

  ngOnInit(): void {

      let currentUrl=this.router.url;

      let currentUrlData1=currentUrl.split('=');
      let currentUrlData2=currentUrlData1[0].replace("/publicsurvey?","")
      this.onRedirect(currentUrlData2,currentUrlData1[0]);

  }
  onRedirect(data:any,newUrlWithId:any):void {
    this.surveyRedirectorService.getSurveys().then(surveys => {
      this.surveys = surveys;
      let redirected=false;
      for (var i=0;i<this.surveys.length;i++) {
        if (this.surveys[i].id===data) {
          window.location.href=this.surveys[i].url;
          redirected=true;
        }
      }
      if (redirected===false) {
        this.surveyRedirectorService.getIfNotFound().then(redirectUrlWithId=>{
          this.redirectUrlWithId=redirectUrlWithId;
          let newUrl=this.redirectUrlWithId+newUrlWithId;
          window.location.href=newUrl;
        });
      }
    });
  }

}
